import React from "react";

const SelectField = React.forwardRef((props, ref) => {
  const {
    label = "",
    id = "",
    options = [],
    className = "",
    onChange = () => {},
    value = "",
    textClassName = "",
    errorMsg = "required",
    required = true,
    onBlur = () =>{},
    initialText="Please select",
    enableFirstOption=false
  } = props;
  let defaultOption = [
    {
      label: initialText,
      value: "",
    },
  ];
  return (
    <div className="form-group selectContainer">
      <p className={`label font-weight-bold text-white ${textClassName}`}>{label}</p>
      <select
        id={id}
        name={id}
        className={`form-control custom-select w-100 ${className}`}
        value={value}
        onChange={onChange}
        required={required}
        onBlur={onBlur}
      >
        {[...defaultOption, ...options].map((option, key) => {
          return (
            <option
              key={key}
              disabled={key === 0 && !enableFirstOption ? true : false}
              value={option.value}
            >
              {option.label}
            </option>
          );
        })}
      </select>
      <div className="invalid-feedback"> {errorMsg} </div>
    </div>
  );
});

export default SelectField;
