import React from "react"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

const TextTemplate = ({ post }) => {
  return (
    <>
      <div className="mt-4 mb-5">
        <Img
          fluid={post.PostImage ? post.PostImage.localFile.childImageSharp.fluid : post.blogImage.localFile.childImageSharp.fluid }
          className="blogImage"
          alt={`${post.PostImage ? post.PostImage?.alternativeText : post.blogImage?.alternativeText}-post-image`}
        />
      </div>
      <div className="postTexts textPostTexts" >
        <ReactMarkdown>
          {post.Description}
        </ReactMarkdown>
      </div>
    </>
  )
}

export default TextTemplate
