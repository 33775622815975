import React, { useState, useReducer } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { AiOutlineCheckCircle } from "react-icons/ai";

import { fullRequest } from "../services/request"
import { yesNoOption } from "../constant"
import InputField from "./common/form/InputField"
import SelectField from "./common/form/SelectField"
import ButtonField from "./common/form/ButtonField"

const initialState = {
   first_name: "",
   last_name: "",
   email: "",
   home_to_sell: "",
}
const reducer = (state, action) => {
   switch (action.type) {
      case action.type:
         return { ...state, [action.type]: action.value }
      default:
         throw new Error()
   }
}
const EmailGate = (props) => {
   const [state, dispatch] = useReducer(reducer, initialState)
   const [formSubmit, setFormSubmit] = useState(false)
   const data = useStaticQuery(graphql`
   query {
      allStrapiTemps {
         nodes {
            CTA_text
            subtitle_text
            title_text
            webhook_URL
            disclaimer
         }
      }
   }`)
   const { CTA_text, subtitle_text, title_text, webhook_URL, disclaimer } = data.allStrapiTemps.nodes[0]

   const onValueChange = (e, type) => {
      dispatch({ type, value: e.target.value })
   }
   const onSubmit = e => {
      e.preventDefault()
      let data = state
      fullRequest({
         url: webhook_URL,
         data,
      })
         .then(() => {
            setFormSubmit(true)
         })
         .catch(err => {
            console.log("onConfirm-re", err)
         })
   }

   return (
      <div className="emailGateContainer">
         <div className="max2000 emailGateBlock">
            <div className="textBlock">
               <h2>
                  {title_text}
               </h2>
               <div className="text-center subTitle px-2">
                  {subtitle_text}
               </div>
            </div>
            {!formSubmit && <form className="d-flex flex-column inputBlocks" onSubmit={e => onSubmit(e)}>
               <div className="d-flex names">
                  <div className="firstName">
                     <InputField
                        id="firstName"
                        placeholder="First Name"
                        type="text"
                        maxLength="50"
                        pattern="^\w+(\s+\w+)*$"
                        value={state.first_name}
                        onChange={e => onValueChange(e, "first_name")}
                        className="form-control getAddressInput "
                        required={true}
                     />
                  </div>
                  <div className="lastName">
                     <InputField
                        id="secondName"
                        placeholder="Last Name"
                        type="text"
                        maxLength="50"
                        pattern="^\w+(\s+\w+)*$"
                        value={state.last_name}
                        onChange={e => onValueChange(e, "last_name")}
                        className="form-control getAddressInput "
                        required={true}
                     />
                  </div>
               </div>
               <div className="d-flex inputSelect">
                  <div className="mt-2 emailId">
                     <InputField
                        id="email"
                        placeholder="Email"
                        maxLength="50"
                        value={state.email}
                        onChange={e => onValueChange(e, "email")}
                        className="form-control getAddressInput "
                        type="email"
                        required={true}
                        errorMsg="Please enter the vaild Email"
                     />
                  </div>
                  <div className="homeToSell">
                     <SelectField
                        id="home_to_sell"
                        className="form-control getAddressInput"
                        options={yesNoOption}
                        value={state.home_to_sell}
                        initialText="Do you have a home to sell?"
                        onChange={e => onValueChange(e, "home_to_sell")}
                     />
                  </div>
               </div>
               <div className="byClick text-center">{disclaimer}</div>
               <div className="text-center mt-3 buttonField">
                  <ButtonField
                     className="LearnMoreBtn"
                     name={CTA_text}
                     type="submit"
                  />
               </div>
            </form>}
            {formSubmit && <div className="text-center successText pt-4">
               <AiOutlineCheckCircle size={40} className="success" />
               <h2>
                  Form <b>successfully</b> submitted, please check your inbox!
               </h2>
            </div>}
         </div>
      </div>
   )
}

export default EmailGate;