import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery, navigate, Link } from "gatsby"
import _kebabCase from "lodash/kebabCase";

import SelectField from "./form/SelectField"
import { BLOG_SCREEN } from "../../constant";

const SelectCategory = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiCategories {
        nodes {
          CategoryName
          CategoryDescription
          slug
        }
      }
    }
 `)
  const [categoryName, setCategoryName] = useState("")
  const categoryList = data.allStrapiCategories.nodes
  const selectCategory = categoryList.map((val) => {
    let selectCategory = { label: val.CategoryName, value: val.CategoryName };
    return selectCategory
  })
  const onvalueSubmit = (data) => {
    setCategoryName(data.CategoryName)
    // navigate(`/${_kebabCase(data.slug.toLowerCase())}/`)
  }
  const onValueChange = (e) => {
    let value = e.target.value
    if (value === "") {
      navigate(BLOG_SCREEN)
    } else {
      let arr = categoryList.filter((val) => val.CategoryName === e.target.value)
      setCategoryName(value)
      navigate(`/${_kebabCase(arr[0].slug.toLowerCase())}/`)
    }
  }
  useEffect(() => {
    setCategoryName(props.categoryValue)
  }, [props.categoryValue])
  return (
    <div className="selectCategory">
      <div className="d-flex flex-wrap categorySection max2000 justify-content-around align-items-center text-center px-5">
        {categoryList.map((data, key) => {
          return (
            <Link
              to={`/${_kebabCase(data.slug.toLowerCase())}/`}
              key={key}
              className={`col-4 col-sm-2 categoryText cursor-pointer ${data.CategoryName === categoryName ? "categoryActive" : ""}`}
              onClick={() => onvalueSubmit(data)}
            >
              {data.CategoryName}
            </Link>
          )
        })}
      </div>
      <div className="selectCategory">
        <SelectField
          options={selectCategory}
          className="list-select"
          value={categoryName}
          initialText="All"
          onChange={e => onValueChange(e)}
          enableFirstOption={true}
        />
      </div>
    </div>
  )
}

export default SelectCategory;
