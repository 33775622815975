import React from "react"
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa"
import moment from 'moment'
import { TiArrowForward } from "react-icons/ti";

import EmailGate from "./emailGate"
import NewsLetter from "./newsLetter"
import BackgroundSection from "./common/bgImage"
import TextTemplate from "./textTemplate"
import ButtonField from "./common/form/ButtonField"
import { HOME_SCREEN } from "../constant";

const SinglePost = props => {
  let date = moment(props.post.published_at).format("MMMM D, YYYY"); 
  const baseUrl = `${process.env.GATSBY_URL}/${props.post.categories[0].slug}/`
  return (
    <div className="blogContainer singlePostContainer">
      <div className="singlePostImage">
        <BackgroundSection
          className="bg-image-cover bgImage displayNone"
          post={props.post}
          alt={`${props.post.blogImage?.alternativeText}-featured-image`}
        />
      </div>
      <div className="singlePostContent">
        <div className="singlePostCard">
          <h1>
            {props.post ? props.post.Title : ""}
          </h1>
          <div className="d-flex align-items-center dateSocialIcons">
            <div className="d-flex my-3 pb-2">
              <div className="pr-4 date">{date}</div>
              <div className="borderRight">|</div>
              <div className="px-4 TeamTBH">Team TBH</div>
              <div className="borderRight displayNone">|</div>
            </div>
            <div className="d-flex ml-2 pb-2">
              <a href={"https://www.facebook.com/sharer/sharer.php?u="+baseUrl+props.post.slug} className="socialMediaCircle fbPadding mx-2" target="_blank" rel="noopener noreferrer">
                <FaFacebookF size={20} className="cursor-pointer" />
              </a>
              <a href={"https://twitter.com/share?url="+baseUrl+props.post.slug+"&text="+props.post.Title+"&viatwitterHandle"} className="socialMediaCircle padding mx-1" target="_blank" rel="noopener noreferrer">
                <FaTwitter size={20} className="cursor-pointer" />
              </a>
              <a href={"https://www.linkedin.com/shareArticle?url="+baseUrl+props.post.slug} className="socialMediaCircle padding mx-1" target="_blank" rel="noopener noreferrer">
                <FaLinkedinIn size={20} className="cursor-pointer" />
              </a>
            </div>
          </div>
          <TextTemplate post={props.post} />
          <div className="mt-5 postDisclaimer">
          This guide is meant for general informational purposes only and is not intended to be used as financial, tax, legal, real estate, insurance, or investment advice.
          </div>
        </div>
        {props?.post?.CTA ? <div className="singlePostCard CTA">
            <div className='grayLine' />
          <div className="textBlurb">Interested in a free cash offer on your home? Click the button below to get started today!</div>
            <div className="arrowButton">
            <TiArrowForward className="arrow" />
              <ButtonField 
                name='Request A Cash Offer'
                fieldName="cash offer"
                className='backToHomeBtn btn-primary cashOfferBtn'
                to={HOME_SCREEN}
              />
            </div>
          </div>: null}
      </div>
      {props.post?.email_gate ? <EmailGate post={props.post}/> : <div className="mt-4"><NewsLetter title={props.title}/></div>}
    </div>
  )
}

export default SinglePost