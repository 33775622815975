import React from "react"
import { graphql } from "gatsby"

import SinglePost from "./singlePost"
import Layout from "../components/layout"
import SelectCategory from "../components/common/selectCategory"

export const query = graphql`
  query blogs($slug: String!) {
    postDetails: strapiBlogs(slug: { eq: $slug }) {
      CTA
      FeaturedImage
      Description
      FeaturedPost
      Subtitle
      Title
      slug
      ourpicks
      updated_at
      published_at
      blogImage {
        alternativeText
        localFile {
          childImageSharp {
            fluid(quality: 0, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200) {
              src
              height
              width
            }
          }
          publicURL
        }
      }
      PostImage {
        alternativeText
        localFile {
          childImageSharp {
            fluid(quality: 0, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      categories {
        CategoryDescription
        CategoryName
        slug
      }
      SEO {
        metaDescription
        metaTitle
        structuredData
      }
      email_gate
    }
  }
`

const Blogs = ({ data }) => {
  return (
    <Layout title="singlePost" postData={data.postDetails} singlePost={true}>
      <div className="categoryContainer blogContainer">
        <SelectCategory
          categoryValue={data.postDetails.categories[0].CategoryName}
        />
        <div>
          <SinglePost post={data.postDetails} title="singlePost" />
        </div>
      </div>
    </Layout>
  )
}

export default Blogs