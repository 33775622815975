import React from "react";

const InputField = React.forwardRef((props, ref) => {
  const { maxLength = "50", pattern, placeholder = "", className = "form-control rounded-0", type = "text", autoComplete = "off", required = false, errorMsg = "* required", onChange = () => { }, value = " ", id = "", onBlur = () => { } } = props
  return (
    <div className="inputContainer">{
      <input ref={ref} type={type} id={id} value={value} pattern={pattern} placeholder={placeholder} maxLength={maxLength} className={className} autoComplete={autoComplete} required={required} onChange={onChange} onBlur={onBlur} />
    }
      <div className="invalid-feedback">
        {errorMsg}
      </div>
    </div>
  );
})

export default InputField;